export const PRICES_DATA = {
  banner: {
    bgImage: 'stardend24-prices__bg.jpg',
    bgImageMobile: 'stardend24-prices__bg--mobile.jpg',
    bgImageTablet: 'stardend24-prices__bg--mobile.jpg',
    title: 'Стоимость лечения',
  },
  list: [
    {
      category: 'Ортопедия',
      sublist: [
        {
          subcategory: `Модели, прикус`,
          services: [
            {
              label: `Цифровое моделирование 3D Smile Design,включая перенос макета (Moke Up) в полость рта, с целью планирования будущей эстетики и функции  (1 единица)`,
              price: `4000`
            },
            {
              label: `Регистрация соотношения зубных рядов`,
              price: `500`
            },
            {
              label: 'Планирование на диагностических моделях челюстей с восковой моделировкой (Wax-Up) будущей ортопедической конструкции с целью планирования препарирования,будущей эстетики и функции (1 единица)',
              price: `2 000`
            },
            {
              label: 'Определение центрального соотношения челюстей с использованием восковых шаблонов',
              price: `4 000`
            },
            {
              label: 'Определение центрального соотношения  зубных рядов с помощью лицевой дуги',
              price: `4 000`
            },
            {
              label: 'WAX UP -восковое моделирование планируемой ортопедической конструкции (1 единица)',
              price: `1 500`
            },
            {
              label: 'Изготовление ключа для переноса результата 3D моделирования в полость рта. 1шт. ( включая печать модели)',
              price: `5 000`
            },
            {
              label: 'WAX UP -восковое моделирование планируемой ортопедической конструкции (1 единица) Эстетик +',
              price: `3 000`
            },
            {
              label: 'Изготовление ключа для переноса результата WAX UP моделирования в полость рта. 1шт. Эстетик +',
              price: `2 000`
            },
            {
              label: 'Печать модели при  цифровом протоколе ортопедического/ортодонтического лечения',
              price: `2 000`
            },
            {
              label: 'Изготовление гипсовых диагностических или рабочих моделей челюстей',
              price: `700`
            },
            {
              label: 'Изготовление защитной ( ночной ) каппы при бруксизме',
              price: `5 000`
            },
          ]
        },
        {
          subcategory: `Отдельные процедуры`,
          services: [
            {
              label: `Трепанация и снятие штампованной коронки`,
              price: `500`
            },
            {
              label: `Трепанация и снятие металлокерамической коронки`,
              price: `1 000`
            },
            {
              label: `Трепанация и снятие литой коронки`,
              price: `1 000`
            },
            {
              label: `Трепанация и снятие циркониевой коронки`,
              price: `1 000`
            },
            {
              label: `Снятие несъемной ортопедической конструкции ( извлечение культевой вкладки)`,
              price: `4 500`
            },
            {
              label: `Извлечение анкерного штифта`,
              price: `3 500`
            },
            {
              label: `Снятие коронки с имплантата (цементная фиксация)`,
              price: `1 500`
            },
            {
              label: `Снятие коронки с имплантата ( винтовая фиксация)`,
              price: `1 000`
            },
            {
              label: `Снятие несъемной ортопедической конструкции (разделение мостовидного протеза)`,
              price: `1 000`
            },
            {
              label: `Цементировка коронки на временный цемент`,
              price: `300`
            },
            {
              label: `Цементировка коронки на стеклоиономерный цемент`,
              price: `500`
            },
            {
              label: `Цементировка коронки на самоадгезивный цемент двойного отверждения`,
              price: `1 000`
            },
            {
              label: `Цементировка коронки по протоколу адгезивной фиксации`,
              price: `1 500`
            },
            {
              label: `Цементировка ( перефиксация ,в т ч винтовая ,с протяжкой абатмента и гигиенической обработкой) коронки на имплантате`,
              price: `2 500`
            },
            {
              label: `Ретракция десны в области одного зуба`,
              price: `500`
            },
            {
              label: `Избирательное пришлифовывание зубов для устранения травматической окклюзии`,
              price: `300`
            },
            {
              label: `Пломбирование зуба для восстановления культи зуба в целях дальнейшего протезирования`,
              price: `3 000`
            },
          ]
        }
      ]
    },
    {
      category: `Съемные протезы`,
      sublist: [
        {
          subcategory: `Протезирование`,      
        services: [
          {
            label: `Протезирование зубов полным съемным пластиночным протезом`,
            price: `35 000`
          },
          {
            label: `Протезирование зубов полным съемным пластиночным протезом с армированием литым базисом`,
            price: `50 000`
          },
          {
            label: `Протезирование зубов полным съемным пластиночным протезом Acry Free`,
            price: `45 000`
          },
          {
            label: `Изготовление хирургического шаблона для имплантации`,
            price: `8 500`
          },
          {
            label: `Протезирование зубов частичным  пластиночным съемным протезом`,
            price: `34 000`
          },
          {
            label: `Протезирование зубов  частичным  пластиночным съемным протезом с армированием`,
            price: `38 000`
          },
          {
            label: `Протезирование зубов малым седловидным съемными протезам для замещения 1-2 отсутствующих зубов (нейлон)`,
            price: `18 000`
          },
          {
            label: `Протезирование зубов малым седловидным съемными протезам для замещения 3-4 отсутствующих зубов (нейлон)`,
            price: `20 000`
          },
          {
            label: `Протезирование зубов  съемным бюгельным протезом с кламмерной фиксацией  КХС`,
            price: `50 000`
          },
          {
            label: `Протезирование зубов съемным бюгельным протезом с эластичным базисом и  кламмерной фиксацией  QuattroTi`,
            price: `55 000`
          },
          {
            label: `Протезирование съемным бюгельным протезом с замковой фиксацией Bredent`,
            price: `50 000`
          },
          {
            label: `Протезирование съемным бюгельным протезом с телескопической фиксацией`,
            price: `55 000`
          },
          {
            label: `Постоянное шинирование зубов  цельнолитыми съемными конструкциями при заболеваниях пародонта (шинирующий бюгель )`,
            price: `36 000`
          },
          {
            label: `Коррекция прикуса с использованием съемных и несъемных ортопедических конструкций – сплинт`,
            price: `8 000`
          },
          {
            label: `Коррекция прикуса с использованием съемных и несъемных ортопедических конструкций – каппа`,
            price: `8 000`
          },
          {
            label: `Изготовление индивидуальной ложки для получения оттиска с уровня имплантата`,
            price: `2 500`
          },
          {
            label: `Изготовление индивидуальной ложки`,
            price: `2 000`
          },
          {
            label: `Протезирование зубов частичным съемным протезом из нейлона`,
            price: `39 000`
          },
          {
            label: `Ремонт перелома базиза пластикового сьемного протеза`,
            price: `6 000`
          },
          {
            label: `Ремонт ( приварка 1-2 зубов в базиз ) сьемного  протеза`,
            price: `7 500`
          },
          {
            label: `Ремонт ( приварка 3-5 зубов в базис )сьемного протеза`,
            price: `8 500`
          },
          {
            label: `Перебазировка базиса сьемного протеза`,
            price: `8 000`
          },
          {
            label: `Протезирование зубов частичным пластиночным протезом Acry Free`,
            price: `40 000`
          },
        ]
      },
      {
        subcategory: `Съемное протезирование с опорой на имплантаты`,
        services: [
          {
            label: `Стоимость 1-го локатора Straumann для съемного протеза`,
            price: `12 000`
          },
          {
            label: `Стоимость 1-го локатора Osstem для съемного протеза`,
            price: `9 000`
          },
          {
            label: `Протезирование зубов полным съемным пластиночным протезом  с опорой на имплантаты на 2х- 4 х локаторах Novaloc`,
            price: `70 000`
          },
          {
            label: `Протезирование зубов полными съемными пластиночными протезами (1 челюсть) с опорой на имплантаты на фрезерованной балке`,
            price: `200 000`
          },
          {
            label: `Восстановление целостности зубного ряда условно съемным протезом из акрила с винтовой фиксацией (10-14 единиц)`,
            price: `250 000`
          },            
          {
            label: `Протезирование зубов полным съемным пластиночным армированным протезом  с опорой на имплантаты на 2 х - 4х локаторах Novaloc`,
            price: `90 000`
          },
        ]
      }
    ]
  },
  {
    category: `Оттиски`,
    sublist: [
      {
        subcategory: `Получение оттиска`,      
      services: [
        {
          label: `Получение оттиска с одной челюсти  альгинатной массой`,
          price: `700`
        },
        {
          label: `Получение оттиска с одной челюсти  (монофазный оттиск)`,
          price: `1 300`
        },
        {
          label: `Получение оттиска с одной челюсти массой из С-силикона`,
          price: `1 200`
        },
        {
          label: `Получение оттиска с одной челюсти массой из А-силикона`,
          price: `2 000`
        },
        {
          label: `Получение оттиска с одной челюсти массой из поливинилсилоксана`,
          price: `2 000`
        },
        {
          label: `Получение оттиска с одной челюсти с использованием индивидуальной ложки`,
          price: `1 500`
        },
        {
          label: `Получение оттиска с одной челюсти  с уровня  имплантата открытым методом`,
          price: `4 000`
        },
        {
          label: `Получение оттиска с одной челюсти с уровня  имплантата закрытым методом`,
          price: `3 000`
        },
        {
          label: `Получение оттиска с одной челюсти с использованием индивидуальной ложки с уровня имплантата открытым методом`,
          price: `4 500`
        },
        {
          label: `Получение оттиска с одной челюсти с использованием индивидуальной ложки с уровня  имплантата закрытым методом`,
          price: `3 500`
        },
        {
          label: `Получение оттиска с одной челюсти для изготовления силиконового ключа`,
          price: `1 000`
        },
      ]
    }
  ]
},
{
  category: `Восстановление зуба`,
  sublist: [
    {
    subcategory: `Восстановление зуба вкладкой,виниром,полукоронкой`,
    services: [
      {
        label: `Вкладка,накладка окклюзионная ЕМах 1 кат`,
        price: `26 000`
      },
      {
        label: `Вкладка,накладка окклюзионная ЕМах 2 кат`,
        price: `28 000`
      },
      {
        label: `Винир  полно анатомический ЕМах`,
        price: `30 000`
      },
      {
        label: `Винир  полно анатомический ЕМах облицованный керамикой`,
        price: `32 000`
      },
      {
        label: `Винир  полно анатомический ЕМах облицованный керамикой Эстетик +`,
        price: `37 000`
      },
    ]
  }
]
},
{
category: `Несъемное протезирование и коронки`,
sublist: [
{
  subcategory: `Несъемное протезирование (восстановление зуба коронкой)`,
  services: [
    {
      label: `Коронка литая (или зуб литой ) из КХС`,
      price: `6 000`
    },
    {
      label: `Коронка временная композитная  фрезерованная лабораторным методом`,
      price: `5 000`
    },
    {
      label: `Коронка временная пластиковая изготовленная лабораторным методом`,
      price: `2 000`
    },
    {
      label: `Коронка временная (быстротвердеющая пластмасса) изготовленная прямым методом`,
      price: `1 000`
    },
    {
      label: `Колпачок временный (быстротвердеющая пластмасса),для защиты отпрепарированного зуба`,
      price: `700`
    },
    {
      label: `Вкладка штифтово культевая КХС. 1  кат`,
      price: `12 000`
    },
    {
      label: `Вкладка штифтово культевая КХС. 2  кат`,
      price: `13 000`
    },
    {
      label: `Вкладка штифтово культевая ZrO2 SuperfectZir. 1 кат`,
      price: `14 000`
    },
    {
      label: `Вкладка штифтово культевая ZrO2 SuperfectZir.2 кат`,
      price: `15 000`
    },
    {
      label: `Цельнолитая  культевая  вкладка  ,с керамической культевой частью ЕМах`,
      price: `17 500`
    },
    {
      label: `Вкладка штифтово культевая КХС. 1 кат с культей покрытой опаком`,
      price: `12 000`
    },
    {
      label: `Коронка полно анатомическая ZrO2 SuperfectZir CAD CAM`,
      price: `20 500`
    },
    {
      label: `Вкладка штифтово культевая КХС. 2  кат с культей покрытой опаком`,
      price: `13 000`
    },
    {
      label: `Коронка полно анатомическая ZrO2 Multilayer CAD CAM`,
      price: `23 000`
    },
    {
      label: `Коронка полно анатомическая ZrO2 KATANA CAD CAM`,
      price: `24 000`
    },
    {
      label: `Коронка анатомическая ZrO2 SuperfectZir CAD CAM облицованная керамикой`,
      price: `23 500`
    },
    {
      label: `Коронка анатомическая ZrO2 Multilayer CAD CAM облицованная керамикой`,
      price: `25 500`
    },
    {
      label: `Коронка анатомическая ZrO2 KATANA CAD CAM облицованная керамикой`,
      price: `27 500`
    },
    {
      label: `Коронка полно анатомическая ЕМах`,
      price: `28 000`
    },
    {
      label: `Коронка  анатомическая ЕМах облицованная керамикой`,
      price: `32 000`
    },
    {
      label: `Коронка металлокерамическая КХС`,
      price: `14 000`
    },
    {
      label: `Коронка металлокерамическая КХС изготовленная в лаборатории Эстетик +`,
      price: `20 000`
    },
    {
      label: `Коронка металлокерамическая КХС фрезерованная CAD CAM`,
      price: `16 000`
    },
    {
      label: `Коронка полно анатомическая ZrO2 SuperfectZir CAD CAM с винтовой фиксацией для имплантата ( включая винт и платформу) универсальная платформа`,
      price: `41 000`
    },
    {
      label: `Коронка полно анатомическая ZrO2 Multilayer CAD CAM с винтовой фиксацией для имплантата ( включая винт и платформу) универсальная платформа`,
      price: `43 000`
    },
    {
      label: `Коронка  анатомическая ZrO2 SuperfectZir CAD CAM с винтовой фиксацией и керамической облицовкой ,для имплантата ( включая винт и платформу) универсальная платформа`,
      price: `44 000`
    },
    {
      label: `Коронка  анатомическая ZrO2 Multilayer CAD CAM с винтовой фиксацией и керамической облицовкой ,для имплантата ( включая винт и платформу) универсальная платформа`,
      price: `45 000`
    },
    {
      label: `Коронка полно анатомическая ZrO2 Multilayer CAD CAM с цементной фиксацией, для имплантата (включая абатмент)`,
      price: `48 000`
    },
    {
      label: `Коронка  анатомическая ZrO2  Multilayer CAD CAM с цементной фиксацией и керамической облицовкой, для имплантата (включая абатмент)`,
      price: `50 000`
    },
    {
      label: `Коронка анатомическая,винир EMax облицованная керамикой изготовленная по технологии Smile Design`,
      price: `42 500`
    },
    {
      label: `Коронка полно анатомическая ZrO2 SuperfectZir CAD CAM с винтовой фиксацией для имплантата ( включая винт и платформу) платформа Variobase Straumann`,
      price: `46 000`
    },
    {
      label: `Коронка полно анатомическая ZrO2 Multilayer CAD CAM с винтовой фиксацией для имплантата ( включая винт и платформу) платформа Variobase Straumann`,
      price: `48 000`
    },
    {
      label: `Коронка  анатомическая ZrO2 SuperfectZir CAD CAM с винтовой фиксацией и керамической облицовкой ,для имплантата ( включая винт и платформу)  платформа Variobase Straumann`,
      price: `48 000`
    },
    {
      label: `Коронка  анатомическая ZrO2 Multilayer CAD CAM с винтовой фиксацией и керамической облицовкой ,для имплантата ( включая винт и платформу) платформа Variobase Straumann`,
      price: `50 000`
    },
    {
      label: `Коронка  анатомическая ЕМах облицованная керамикой изготовленная в лаборатории Эстетик+`,
      price: `38 000`
    },
    {
      label: `Коронка анатомическая ZrO2 Multilayer CAD CAM облицованная керамикой Эстетик +`,
      price: `32 000`
    },
    {
      label: `Коронка временная РММА фрезерованная Эстетик +`,
      price: `6 000`
    },
    {
      label: `Коронка временная РММА для имплантата  ( включая винт и платформу )`,
      price: `15 000`
    },
    {
      label: `Коронка полно анатомическая ZrO2 SuperfectZir CAD CAM с винтовой фиксацией для имплантата ( включая винт и платформу) платформа Osstem`,
      price: `43 000`
    },
    {
      label: `Коронка полно анатомическая ZrO2 Multilayer CAD CAM с винтовой фиксацией для имплантата ( включая винт и платформу) платформа Osstem`,
      price: `44 000`
    },
    {
      label: `Коронка  анатомическая ZrO2 SuperfectZir CAD CAM с винтовой фиксацией и керамической облицовкой ,для имплантата ( включая винт и платформу)  платформа Osstem`,
      price: `45 000`
    },
    {
      label: `Коронка  анатомическая ZrO2 Multilayer CAD CAM с винтовой фиксацией и керамической облицовкой ,для имплантата ( включая винт и платформу) платформа Osstem`,
      price: `46 000`
    },
  ]
}
]
},
    {
      category: `Консультация`,
      sublist: [
        {
          services: [
            {
              label: `Прием (осмотр, консультация) врача-ортодонта первичный`,
              price: `1000`
            },
            {
              label: `Прием (осмотр, консультация) врача-ортодонта повторный`,
              price: `0`
            },
            {
              label: `Прием (осмотр, консультация) врача-стоматолога детского первичный`,
              price: `500`
            },
            {
              label: `Прием (осмотр, консультация) врача-стоматолога детского повторный`,
              price: `0`
            },
            {
              label: `Прием (осмотр, консультация) врача-стоматолога-терапевта первичный`,
              price: `500`
            },
            {
              label: `Прием (осмотр, консультация) врача-стоматолога-терапевта повторный`,
              price: `0`
            },
            {
              label: `Прием (осмотр, консультация) врача- стоматолога общей практики первичный`,
              price: `500`
            },
            {
              label: `Прием (осмотр, консультация) врача- стоматолога общей практики повторный`,
              price: `0`
            },
            {
              label: `Прием (осмотр, консультация) врача-стоматолога первичный`,
              price: `500`
            },
            {
              label: `Прием (осмотр, консультация) врача-стоматолога повторный`,
              price: `0`
            },
            {
              label: `Прием (осмотр, консультация) врача-стоматолога-ортопеда первичный`,
              price: `1000`
            },
            {
              label: `Прием (осмотр, консультация) врача-стоматолога-ортопеда повторный`,
              price: `0`
            },
            {
              label: `Прием (осмотр, консультация) врача-стоматолога-хирурга первичный`,
              price: `500`
            },
            {
              label: `Прием (осмотр, консультация) врача-стоматолога-хирурга повторный`,
              price: `0`
            },
            {
              label: `Прием (осмотр, консультация) врача-стоматолога-хирурга пародонтолога первичный`,
              price: `500`
            },
            {
              label: `Прием (осмотр, консультация) врача-стоматолога-хирурга пародонтолога повторный`,
              price: `0`
            },
          ]
        }
      ]
    },
    {
      category: `Хирургия`,
      sublist: [
        {
          subcategory: `Имплантация (хирургическая часть)`,
          services: [
            {
              label: `Внутрикостная дентальная имплантация системы Straumann SLA`,
              price: `52 000`
            },
            {
              label: `Внутрикостная дентальная имплантация системы Straumann SLActive`,
              price: `57 000`
            },
            {
              label: `Внутрикостная дентальная имплантация ортодонтического имплантата ( включая ортодонтический имплантат)`,
              price: `25 000`
            },
            {
              label: `Установка формирователя десны Straumann`,
              price: `7 000`
            },
            {
              label: `Внутрикостная дентальная имплантация системы Anthogyr`,
              price: `45 000`
            },
            {
              label: `Внутрикостная дентальная имплантация системы Osstem`,
              price: `42 000`
            },
            {
              label: `Установка формирователя десны`,
              price: `6 000`
            },
            {
              label: `Установка индивидуализированного ( по контуру прорезывания ) формирователя десны`,
              price: `9 500`
            },
            {
              label: `Удаление имплантата`,
              price: `10 000`
            },
            {
              label: `Внутрикостная дентальная имплантация ортодонтического (имплантат пациента)`,
              price: `15 000`
            },
          ]
        },
        {
          subcategory: ` Пародонтология хирургическая`,
          services: [
            {
              label: `Закрытие рецессии десны в обл 1-го зуба`,
              price: `7 000`
            },
            {
              label: `Закрытие рецессии десны в обл 3-4 зубов`,
              price: `15 000`
            },
            {
              label: `Закрытие рецессии десны в обл 5-6  зубов`,
              price: `20 000`
            },
            {
              label: `Закрытие рецессии десны с помощью трансплантата с неба в обл 1-го зуба`,
              price: `16 000`
            },
            {
              label: `Закрытие рецессии десны с помощью трансплантата с неба в обл 3-4 зубов`,
              price: `32 000`
            },
            {
              label: `Закрытие рецессии десны с помощью Mucograft`,
              price: `30 000`
            },
            {
              label: `Закрытие рецессии десны с помощью Mucograft и Emdogain`,
              price: `45 000`
            },
            {
              label: `Закрытие рецессии десны с помощью Emdogain в обл 1-го зуба`,
              price: `25 000`
            },
            {
              label: `Туннельная методика закрытия рецессий`,
              price: `23 000`
            },
            {
              label: `Туннельная методика закрытия рецессий с использованием трансплантата с неба`,
              price: `45 000`
            },
            {
              label: `Наложение повязки при операциях в полости рта`,
              price: `500`
            },
            {
              label: `Наложение повязки при операциях в полости рта (СтимулОсс)`,
              price: `500`
            },
            {
              label: `Наложение повязки при операциях в полости рта (Resorba)`,
              price: `1 000`
            },
            {
              label: `Удаление зуба`,
              price: `5 000`
            },
            {
              label: `Удаление временного зуба`,
              price: `2 500`
            },
            {
              label: `Удаление постоянного зуба`,
              price: `5 000`
            },
            {
              label: `Удаление зуба сложное с разъединением корней`,
              price: `7 000`
            },
            {
              label: `Удаление 8 го зуба с визуализированной коронковой частью`,
              price: `8 000`
            },
            {
              label: `Удаление подвижного зуба ( 3 степ подвижности)`,
              price: `2 000`
            },
            {
              label: `Удаление корня зуба ( 3 степ подвижности )`,
              price: `3 000`
            },
            {
              label: `Удаление корня зуба`,
              price: `4 000`
            },
            {
              label: `Удаление подвижного зуба ( 2 степ подвижности )`,
              price: `3 500`
            },
            {
              label: `Резекция верхушки корня`,
              price: `8 000`
            },
            {
              label: `Вскрытие подслизистого или поднадкостничного очага воспаления в полости рта`,
              price: `1 500`
            },
            {
              label: `Вскрытие и дренирование одонтогенного абсцесса`,
              price: `3 000`
            },
            {
              label: `Отсроченный кюретаж лунки удаленного зуба`,
              price: `1 500`
            },
            {
              label: `Вскрытие и дренирование абсцесса полости рта`,
              price: `3 000`
            },
            {
              label: `Цистотомия или цистэктомия`,
              price: `1 000`
            },
            {
              label: `Пластика альвеолярного отростка с учетом  костнопластического материала при удалении 2-3 рядом стоящих зубов`,
              price: `15 000`
            },
            {
              label: `Коррекция объема и формы альвеолярного отростка`,
              price: `5 000`
            },
            {
              label: `Операция удаления ретинированного, дистопированного или сверхкомплектного зуба`,
              price: `12 000`
            },
            {
              label: `Гингивэктомия,в тч контурирование десневого контура в эстетических целях`,
              price: `1 000`
            },
            {
              label: `Открытый кюретаж при заболеваниях пародонта в области зуба`,
              price: `3 500`
            },
            {
              label: `Лоскутная операция в полости рта ( сегмент 3-4 зуба )`,
              price: `15 000`
            },
            {
              label: `Пластика малого костного дефекта при имплатации,включая остеопластический материал`,
              price: `6 000`
            },
            {
              label: `Направленная костная регенерация в обл удаленного зуба при одномоментной имплантации с учетом остеопластического материала`,
              price: `15 000`
            },
            {
              label: `Направленная костная регенерация в обл удаленного зуба с учетом остеопластического материала`,
              price: `15 000`
            },
            {
              label: `Пластика уздечки верхней губы`,
              price: `5 000`
            },
            {
              label: `Пластика уздечки нижней губы`,
              price: `5 000`
            },
            {
              label: `Пластика уздечки языка`,
              price: `5 500`
            },
            {
              label: `Вестибулопластика`,
              price: `6 500`
            },
            {
              label: `Синус-лифтинг ( открытая методика) 1 кат ( в обл 1-2 зубов) включая все материалы`,
              price: `65 000`
            },
            {
              label: `Синус-лифтинг ( открытая методика) 2 кат ( в обл 3-5 зубов) включая все материалы`,
              price: `80 000`
            },
            {
              label: `Синус-лифтинг ( закрытая методика ) 1 кат ( в обл 1-2 зубов) включая все материалы`,
              price: `20 000`
            },
            {
              label: `Синус-лифтинг ( закрытая методика ) 2 кат  ( в обл 3-5 зубов) включая все материалы`,
              price: `25 000`
            },
            {
              label: `Лечение перикоронита (промывание, рассечение и/или иссечение капюшона)`,
              price: `5 000`
            },
            {
              label: `Гемисекция зуба`,
              price: `5 000`
            },
            {
              label: `Сошлифовывание одного экзостоза механическое .верхняя или нижняя челюсть`,
              price: `5 000`
            },
            {
              label: `Остановка луночного кровотечения без наложения швов`,
              price: `500`
            },
            {
              label: `Остановка луночного кровотечения без наложения швов с использованием гемостатических материалов`,
              price: `1 000`
            },
            {
              label: `Пластика перфорации верхнечелюстной пазухи`,
              price: `15 000`
            },
            {
              label: `Наложение шва на слизистую оболочку рта`,
              price: `450`
            },
          ]
        },
      ]
    },
    {
      category: `Рентгенология и сканирование `,
      sublist: [
        {
          services: [
            {
              label: `Цифровая Ортопантомография`,
              price: `2 000`
            },
            {
              label: `Цифровая Ортопантомография (контрольный снимок)`,
              price: `1 000`
            },
            {
              label: `Цифровая Ортопантомография (с описанием )`,
              price: `2 500`
            },
            {
              label: `Цифровая Ортопантомография (с записью на цифровой носитель)`,
              price: `2 500`
            },
            {
              label: `Радиовизиография в области 1-2 зубов`,
              price: `700`
            },
            {
              label: `Радиовизиография в области 1-2 зубов ( контрольный снимок)`,
              price: `400`
            },
            {
              label: `Радиовизиография в области 1-2 зубов ( с описанием )`,
              price: `1 000`
            },
            {
              label: `Компьютерная томография (КЛКТ) верхней и нижней челюсти (без описания)`,
              price: `5 000`
            },
            {
              label: `Компьютерная томография (КЛКТ) одной челюсти ( без описания)`,
              price: `2 500`
            },
            {
              label: `Компьютерная томография (КЛКТ) придаточных пазух носа ( без описания)`,
              price: `2 500`
            },
            {
              label: `Компьютерная томография (КЛКТ) фрагмента челюсти ( 1-3 зуба без описания)`,
              price: `1 500`
            },
            {
              label: `Запись результата КЛКТ на цифровой носитель (Флеш)`,
              price: `1 000`
            },
            {
              label: `Описание и интерпретация рентгенографических изображений`,
              price: `500`
            },
            {
              label: `Описание и интерпретация компьютерной томографии`,
              price: `1 000`
            },
            {
              label: `Цифровое интраоральное сканирование челюстей `,
              price: `5 000`
            },
            {
              label: `Цифровое интраоральное сканирование одной челюсти `,
              price: `3 000`
            },
          ]
        }
      ]
    },
    { 
      category: `Терапия`,
         sublist: [
           {
             subcategory: `Восстановление зуба пломбой`,
             services: [
               {
                 label: `Восстановление зуба виниром, полукоронкой из фотополимерного материала прямым методом`,
                 price: `7 000`
               },
               {
                 label: `Восстановление зуба пломбой`,
                 price: `5 000`
               },
               {
                 label: `Формирование  полости зуба поврежденного кариесом`,
                 price: `500`
               },
               {
                 label: `Постановка  матрицы`,
                 price: `300`
               },
               {
                 label: `Ретракция десны с использованием ретракционной нити в обл одного зуба`,
                 price: `500`
               },
               {
                 label: `Постановка прокладки самополимеризующейся ( СИЦ,Лак)`,
                 price: `500`
               },
               {
                 label: `Постановка лечебной прокладки (химического или светоотверждения)`,
                 price: `1 500`
               },
               {
                 label: `Восстановление культевой части зуба композитом светового отверждения в целях дальнейшего протезирования`,
                 price: `3 000`
               },
               {
                 label: `Пломбирование  полости   пломбой из композита светового отверждения ( 1 поверхность )`,
                 price: `3 000`
               },
               {
                 label: `Герметизация фиссур 1-го зуба фотополимеризующимся композитом`,
                 price: `3 000`
               },
               {
                 label: `Восстановление зуба пломбой из эстетического композитного материала для эстетически важной зоны`,
                 price: `6 000`
               },
               {
                 label: `Полировка пломбы`,
                 price: `300`
               },
               {
                 label: `Восстановление разрушенного зуба с использованием силиконового ключа`,
                 price: `8 000`
               },
               {
                 label: `Анатомическое восстановление коронковой части зуба с применением стекловолоконного штифта`,
                 price: `12 000`
               },
               {
                 label: `Лечение с применением системы КофферДам`,
                 price: `1 000`
               },
               {
                 label: `Лечение с применением  системы Optragate`,
                 price: `600`
               },
               {
                 label: `Восстановление культевой части зуба с применением стекловолоконного штифта, в целях дальнейшего протезирования`,
                 price: `9 000`
               },
               {
                 label: `Эстетико-функциональное восстановление зуба боковой группы`,
                 price: `8 800`
               },
               {
                 label: `Эстетико-функциональное восстановление зуба фронтальной группы`,
                 price: `9 300`
               },
               {
                 label: `Восстановление культевой части зуба композитом светового отверждения в целях дальнейшего протезирования 2 кат`,
                 price: `6 700`
               },
             ]
           },
           {
             subcategory: `Временные пломбы`,
             services: [
               {
                 label: `Наложение временной пломбы`,
                 price: `400`
               },
               {
                 label: `Наложение временной пломбы ( фотополимер)`,
                 price: `600`
               },
               {
                 label: `Снятие временной пломбы`,
                 price: `100`
               },
             ]
           }
         ]
       },
       { 
        category: `Лечение осложнений кариеса`,
          sublist: [
       {
         subcategory: `Эндодонтическое лечение корневых каналов`,
         services: [
           {
             label: `Фиксация внутриканального штифта/вкладки`,
             price: `1 000`
           },
           {
             label: `Извлечение штифтово-культевой вкладки`,
             price: `4 500`
           },
           {
             label: `Извлечение внутриканального стекловолоконного  штифта`,
             price: `3 500`
           },
           {
             label: `Пломбирование 1-го корневого канала зуба пастой`,
             price: `800`
           },
           {
             label: `Пломбирование 1-го корневого канала зуба гуттаперчевыми штифтами`,
             price: `1 500`
           },
           {
             label: `Пломбирование 1-го корневого канала зуба горячей гуттаперчей методом вертикальной конденсации`,
             price: `3 000`
           },
           {
             label: `Временное пломбирование 1-го корневого канала зуба кальцийсодержащей пастой`,
             price: `1 200`
           },
           {
             label: `Внутриканальное отбеливание измененного в цвете зуба`,
             price: `600`
           },
           {
             label: `Закрытие перфорации стенки корневого канала зуба (Pro-root)`,
             price: `5 000`
           },
           {
             label: `Измерение длины корневого канала с помощью Апекс-Локатора`,
             price: `300`
           },
           {
             label: `Ручная инструментальная обработка 1-го корневого канала`,
             price: `700`
           },
           {
             label: `Машинная инструментальная обработка 1-го корневого канала (электро эндо-мотор)`,
             price: `800`
           },
           {
             label: `Временное пломбирование лекарственным препаратом корневого канала`,
             price: `500`
           },
                 {
             label: `Ультразвуковое расширение 1-го корневого канала зуба`,
             price: `1 500`
           },
                 {
             label: `Ручная инструментальная обработка 1-го плохо проходимого корневого канала`,
             price: `800`
           },
                 {
             label: `Машинная инструментальная обработка 1-го плохо проходимого корневого канала (электро эндо-мотор)`,
             price: `1 000`
           },
                 {
             label: `Обработка 1-го  корневого канала ультразвуковым инструментом`,
             price: `1 000`
           },
                 {
             label: `Медикаментозная обработка 1-го корневого канала`,
             price: `400`
           },
                 {
             label: `Инструментальная и медикаментозная обработка 1-го корневого канала`,
             price: `2 200`
           },
                 {
             label: `Распломбировка 1-го корневого канала ранее леченного пастой`,
             price: `1 500`
           },
                 {
             label: `Распломбировка 1-го корневого канала ранее леченного фосфат- цементом/ резорцин-формальдегидным методом`,
             price: `2 000`
           },
         ]
       },
       {
        subcategory: `Лечение пульпита, периодонтита`,
        services: [
          {
            label: `Лечение пульпита 1 канального зуба (вкл ручную, механическую и медикаментозную обработку, апекслокатор, временную пломбу и постоянную пломбировку канала)`,
            price: `10 800`
          },
          {
            label: `Лечение пульпита 2х канального зуба (вкл ручную, механическую и медикаментозную обработку, апекслокатор, временную пломбу и постоянную пломбировку каналов)`,
            price: `13 300`
          },
          {
            label: `Лечение пульпита 3х канального зуба (вкл ручную, механическую и медикаментозную обработку, апекслокатор, временную пломбу и постоянную пломбировку каналов)`,
            price: `18 600`
          },
          {
            label: `Лечение пульпита 4х канального зуба (вкл ручную, механическую и медикаментозную обработку, апекслокатор, временную пломбу и постоянную пломбировку каналов)`,
            price: `20 600`
          },
          {
            label: `Лечение периодонтита 1 канального зуба ( вкл ручную, механическую, ультразвуковую и медикаментозную обработку, апекслокатор, временную пломбу, временную и постоянную пломбировку канала) за 2 приема`,
            price: `12 000`
          },
          {
            label: `Лечение периодонтита 2х канального зуба ( вкл ручную, механическую, ультразвуковую и медикаментозную обработку, апекслокатор, временную пломбу, временную и постоянную пломбировку каналов) за 2 приема`,
            price: `17 000`
          },
          {
            label: `Лечение периодонтита 3х канального зуба ( вкл ручную, механическую, ультразвуковую и медикаментозную обработку, апекслокатор, временную пломбу, временную и постоянную пломбировку каналов) за 2 приема`,
            price: `23 900`
          },
          {
            label: `Лечение периодонтита 4х канального зуба ( вкл ручную, механическую, ультразвуковую и медикаментозную обработку, апекслокатор, временную пломбу, временную и постоянную пломбировку каналов) за 2 приема`,
            price: `26 000`
          },
        ]
      }, 
     ]
   },
       { 
        category: `Пародонтология терапевтическая`,
          sublist: [
       {
         subcategory: `Пародонтология терапевтическая`,
         services: [
           {
             label: `Изготовление боксерской каппы`,
             price: `6 000`
           },
           {
             label: `Исследование зубодесневых карманов с помощью пародонтологического зонда`,
             price: `1 200`
           },
           {
             label: `Введение лекарственных препаратов в пародонтальный карман`,
             price: `200`
           },
           {
             label: `Наложение лечебной повязки при заболеваниях слизистой оболочки полости рта и пародонта в области одной челюсти`,
             price: `1 000`
           },
           {
             label: `Удаление наддесневых и поддесневых зубных отложений в области зуба ручным методом (КЮРЕТАЖ)`,
             price: `900`
           },
           {
             label: `Применение  Air-Flow`,
             price: `2 000`
           },
           {
             label: `Отбеливание Opalescence Boost 1-го зуба`,
             price: `3 500`
           },
           {
             label: `Отбеливание Opalescence Boost 6 зубов`,
             price: `8 000`
           },
           {
             label: `Реминерализация зуба в клинических условиях`,
             price: `1 500`
           },
           {
             label: `Удаление зубного налета и полировка зубов с помощью специализированного вращающегося инструмента`,
             price: `1 500`
           },
           {
             label: `Гидроорошение при заболевании полости рта и зубов`,
             price: `200`
           },
           {
             label: `Ультразвуковая обработка пародонтального кармана в области зуба`,
             price: `300`
           },
           {
             label: `Ультразвуковое удаление наддесневых и поддесневых зубных отложений . (сегмент 4-6 зубов)`,
             price: `1 500`
           },
         ]
       },
    ]
   },   
    {
      category: `Анестезия и инъекции`,
      sublist: [
        {
          services: [
            {
              label: `Местная анестезия интралигаментарная`,
              price: `500`
            },
            {
              label: `Проводниковая анестезия`,
              price: `800`
            },
            {
              label: `Ирригационная анестезия`,
              price: `100`
            },
            {
              label: `Аппликационная анестезия`,
              price: `150`
            },
            {
              label: `Инфильтрационная анестезия`,
              price: `600`
            },
            {
              label: `Взятие крови из переферической вены. В хирургических целях`,
              price: `1 000`
            },
            {
              label: `Антигистаминная подготовка`,
              price: `300`
            },
            {
              label: `Внутримышечное введение обезболивающих и противовоспалительных препаратов`,
              price: `1 000`
            },
          ]
        }
      ]
    },
    {
      category: `Исследования и диагностика`,
      sublist: [
        {
          services: [
            {
              label: `Антропометрические исследования (медицинское фотографирование)`,
              price: `1 000`
            },
          ]
        }
      ]
    },
    {
      category: `Ортодонтия`,
      sublist: [
        {
          services: [
            {
              label: `Описание и интерпретация рентгенографических изображений ТРГ (1 снимок)`,
              price: `2 000`
            },
            {
              label: `Ортодонтическая коррекция Активация системы (смена дуги)`,
              price: `6 000`
            },
            {
              label: `Аппарат для удержания места ( кольцо-петля )`,
              price: `10 000`
            },
            {
              label: `Ортодонтическая коррекция пластиночным одночелюстным ортодонтическим аппаратом с винтом`,
              price: `25 000`
            },
            {
              label: `Ортодонтическая коррекция съемным аппаратом эластопозиционер`,
              price: `23 500`
            },
            {
              label: `Ортодонтическая коррекция  одночелюстным ортодонтическим аппаратом 2/4 D-Gainer`,
              price: `35 000`
            },
            {
              label: `Ортодонтическая коррекция несъемным ортодонтическим аппаратом HAAS (вклюая его изготовление и установку)`,
              price: `45 000`
            },
            {
              label: `Ортодонтическая коррекция несъемным ортодонтическим аппаратом HAAS ( АКТИВАЦИЯ И НАБЛЮДЕНИЕ)`,
              price: `1 500`
            },
            {
              label: `Фиксация ретейнера`,
              price: `6 000`
            },
            {
              label: `Динамическое наблюдение на этапах ортодонтичского лечения`,
              price: `3 000`
            },
            {
              label: `Ортодонтическая коррекция (фиксация) с применением брекет-системы металлической Damon Q H4 на  один зубной ряд`,
              price: `35 000`
            },
            {
              label: `Ортодонтическая коррекция (фиксация) с применением брекет-системы керамической Damon Clear на один зубной ряд`,
              price: `55 000`
            },
            {
              label: `Ортодонтическая коррекция с применением брекет-системы металлической Damon Q H4 за весь курс лечения 1 кат сложности`,
              price: `230 000`
            },
            {
              label: `Ортодонтическая коррекция с применением брекет-системы металлической Damon Q H4 за весь курс лечения 2 кат сложности`,
              price: `260 000`
            },
            {
              label: `Ортодонтическая коррекция с применением брекет-системы керамической Damon Clear / Experience ceramic за весь курс лечения 1 кат сложности`,
              price: `270 000`
            },
            {
              label: `Ортодонтическая коррекция с применением брекет-системы керамической Damon Clear / Experience ceramic за весь курс лечения 2 кат сложности`,
              price: `300 000`
            },
            {
              label: `Фиксация одного брекета ,щёчной трубки`,
              price: `3 000`
            },
            {
              label: `Лечение на элайнерах  Еврокаппа ( полная коорекция прикуса )`,
              price: `350 000`
            },
            {
              label: `Лечение на элайнерах  Еврокаппа ( полная коорекция прикуса ) 1 челюсть `,
              price: `210 000`
            },
            {
              label: `Ортодонтическая коррекция`,
              price: `3 500`
            },
            {
              label: `Починка ретейнера (1 звено)`,
              price: `1 100`
            },
            {
              label: `Коррекция ортодонтического аппарата ( пластинки ) изготовленной в другом ЛПУ`,
              price: `1 100`
            },
            {
              label: `Фиксация ортодонтической кнопки`,
              price: `1 200`
            },
            {
              label: `Фиксация эластического элемента (лигатура, кольцо,1 звено цепочки )`,
              price: `1 200`
            },
            {
              label: `Лечение с использованием лицевой маски ( Включая припасовку , наложение лицевой маски и обучение пользованием аппаратом )`,
              price: `36 000`
            },
            {
              label: `Изготовление пластиночного аппарата на верхнюю челюсть с крючками  ( без винта)`,
              price: `30 000`
            },
            {
              label: `Диагностика ( слепки , фотографии, антропометрический расчёт моделей)`,
              price: `4 000`
            },
            {
              label: `Снятие брекет системы с одного зубного ряда`,
              price: `5 000`
            },
            {
              label: `Изменение пложения зуба ( до 2х зубов ,с помощью 1 го мини винта ) в целях рационального протезирования`,
              price: `25 000`
            },
            {
              label: `Изменение пложения зуба ( до 4х зубов ,с помощью 1 го мини винта ) в целях рационального протезирования`,
              price: `30 000`
            },
            {
              label: `Изменение пложения зуба ( до 5х зубов ,с помощью 2х  мини винтов ) в целях рационального протезирования`,
              price: `37 000`
            },
            {
              label: `Снятие ретейнера`,
              price: `2 000`
            },
          ]
        }
      ]
    },
    {
      category: `Профилактика`,
      sublist: [
        {
          services: [
            {
              label: `Глубокое фторирование эмали зуба`,
              price: `1 500`
            },
            {
              label: `Аппликация лекарственного препарата на слизистую оболочку полости рта`,
              price: `250`
            },
            {
              label: `Местное применение реминерализующих препаратов в области зуба`,
              price: `350`
            },
            {
              label: `Обучение гигиене полости рта и зубов индивидуальное, подбор средств и предметов гигиены полости рта`,
              price: `500`
            },
            {
              label: `Профессиональная гигиена полости рта и зубов 1 кат`,
              price: `6 000`
            },
            {
              label: `Профессиональная гигиена полости рта и зубов 2 кат`,
              price: `7 500`
            },
            {
              label: `Снятие твердых и мягких зубных отложений с помощью ультразвукового скейлера,полировка . ( 1 сегмент )`,
              price: `1 500`
            },
            {
              label: `Запечатывание фиссуры зуба герметиком`,
              price: `3 000`
            },
            {
              label: `Профессиональная гигиена одной челюсти ,  в т ч при ортодонтическом лечении`,
              price: `3 000`
            },
            {
              label: `Профессиональная гигиена полости рта и зубов 2 кат`,
              price: `7 500`
            },
            {
              label: `Ультразвуковое удаление наддесневых и поддесневых зубных отложений,AirFlow ,полировка, в области зубов одной челюсти`,
              price: `2 500`
            },
            {
              label: `Школа психологической профилактики для пациентов и родственников (адаптивный прием)`,
              price: `3 000`
            },
          ]
        }
      ]
    },
    {
      category: `Профессиональное отбеливание зубов`,
      sublist: [
        {
          services: [
            {
              label: `Профессиональное отбеливание зубов клиническое  за один визит (2 челюсти в линии улыбки) аппаратом ZOOM-4`,
              price: `45 000`
            },
            {
              label: `Профессиональное отбеливание зубов домашнее 2 челюсти (включает стоимость изготовления капп и стандартный набор отбеливающего геля)`,
              price: `9 000`
            },
          ]
        }
      ]
    },
    {
      category: `Терапия детская`,
      sublist: [
        {
          subcategory: `Профилактика`,
          services: [
            {
              label: `Люминесцентная стоматоскопия`,
              price: `1 000`
            },
            {
              label: `Транс Люминесцентная стоматоскопия`,
              price: `1 000`
            },
            {
              label: `Глубокое фторирование эмали зуба`,
              price: `150`
            },
            {
              label: `Аппликация лекарственного препарата на слизистую оболочку полости рта`,
              price: `300`
            },
            {
              label: `Местное применение реминерализующих препаратов в области зуба`,
              price: `500`
            },
            {
              label: `Витальное окрашивание твердых тканей зуба`,
              price: `300`
            },
            {
              label: `Обучение гигиене полости рта и зубов индивидуальное, подбор средств и предметов гигиены полости рта`,
              price: `1 000`
            },
            {
              label: `Профессиональная гигиена полости рта и зубов ( молочный прикус ). Включая все манипуляции и материалы`,
              price: `3 000`
            },
            {
              label: `Профессиональная гигиена полости рта и зубов ( сменный прикус ). Включая все манипуляции и материалы`,
              price: `4 500`
            },
            {
              label: `Запечатывание фиссуры зуба герметиком (временный зуб)`,
              price: `2 500`
            },
            {
              label: `Профессиональная гигиена полости рта и зубов ( сменный прикус ) налет Пристли`,
              price: `5 500`
            },
            {
              label: `Назначение диетического питания при заболеваниях полости рта и зубов`,
              price: `500`
            },
            {
              label: `Назначение лечебно-оздоровительного режима при заболеваниях полости рта и зубов`,
              price: `500`
            },
            {
              label: `Школа психологической профилактики для пациентов и родственников (адаптивный прием)`,
              price: `2 000`
            },
            {
              label: `Выдача врачём стоматологом детским, справки о санации полости рта ( при условии полной санации), включая инструментальный и визуальный осмотр`,
              price: `500`
            },
          ]
        },
        {
          subcategory: `Восстановление временного зуба пломбой`,
          services: [
            {
              label: `Сошлифовывание твердых тканей зуба временного  при лечении кариеса и его осложнений`,
              price: `700`
            },
            {
              label: `Восстановление зуба временного пломбой`,
              price: `3 000`
            },
            {
              label: `Восстановление зуба с использованием стоматологических цементов (временный зуб)`,
              price: `2 000`
            },
            {
              label: `Восстановление зуба временного ,  пломбой с нарушением контактного пункта  с использованием стоматологических цементов`,
              price: `2 500`
            },
            {
              label: `Восстановление зуба временного , пломбой  с использованием стеклоиономерных цементов`,
              price: `2 500`
            },
            {
              label: `Наложение временной пломбы`,
              price: `300`
            },
            {
              label: `Восстановление зуба временного ,пломбой  с использованием материалов из фотополимеров`,
              price: `3 000`
            },
            {
              label: `Восстановление зуба временного ,  пломбой с нарушением контактного пункта  с использованием материалов из фотополимеров`,
              price: `4 000`
            },
            {
              label: `Восстановление зуба временного  пломбой IV класс по Блэку с использованием материалов из фотополимеров`,
              price: `3 500`
            },
            {
              label: `Восстановление постоянного зуба с использованием материалов из фотополимеров`,
              price: `5 000`
            },
            {
              label: `Эстетическая реставрация зуба, пациенту детского возраста (временный зуб)`,
              price: `5 000`
            },
            {
              label: `Коронка стандартная металлическая для покрытия временного зуба`,
              price: `5 000`
            },
          ]
        },
        {
          subcategory: `Лечение осложнений кариеса временных зубов (эндодонтическое лечение корневых каналов)`,
          services: [
            {
              label: `Лечение пульпита  временного зуба (все манипуляции и материалы)`,
              price: `6 000`
            },
            {
              label: `Лечение пульпита временного зуба ( метод Ампутации )`,
              price: `3 500`
            },
            {
              label: `Наложение девитализирующей пасты`,
              price: `200`
            },
            {
              label: `Пломбирование корневого канала зуба`,
              price: `1 200`
            },
            {
              label: `Пломбирование корневого канала зуба пастой`,
              price: `1 200`
            },
            {
              label: `Закрытие перфорации стенки корневого канала зуба Pro Root MTA`,
              price: `5 000`
            },
            {
              label: `Инструментальная и медикаментозная обработка корневого канала`,
              price: `900`
            },
            {
              label: `Инструментальная и медикаментозная обработка хорошо проходимого корневого канала`,
              price: `700`
            },
            {
              label: `Инструментальная и медикаментозная обработка плохо проходимого корневого канала`,
              price: `1 500`
            },
            {
              label: `Временное пломбирование лекарственным препаратом корневого канала`,
              price: `1 000`
            },
            {
              label: `Распломбировка корневого канала ранее леченного пастой`,
              price: `1 500`
            },
            {
              label: `Распломбировка корневого канала ранее леченного фосфат- цементом/ резорцин-формальдегидным методом`,
              price: `2 000`
            },
            {
              label: `Ультразвуковое расширение корневого канала зуба`,
              price: `1 500`
            },
          ]
        }
      ]
    },
    {
      category: `Лабораторные конструкции`,
      sublist: [
        {
          services: [
            {
              label: `Ремонт ортодонтического аппарата`,
              price: `7 000`
            },
            {
              label: `Коррекция съемного протеза изготовленного вне данного ЛПУ`,
              price: `2 000`
            },
            {
              label: `Изготовление разборной модели`,
              price: `500`
            },
            {
              label: `Перебазировка съемного протеза лабораторным методом`,
              price: `6 000`
            },
            {
              label: `Приварка кламмера`,
              price: `1 500`
            },
            {
              label: `Приварка зуба`,
              price: `4 500`
            },
            {
              label: `Починка перелома базиса самотвердеющей пластмассой`,
              price: `2 000`
            },
            {
              label: `Изготовление эластичной прокладки (лабораторный метод)`,
              price: `6 000`
            },
          ]
        }
      ]
    },
  ]
}
